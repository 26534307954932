@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  color: white;
  background-color: black;
}

::selection {
  background: #D9FF6E;
  color: black;
}


/*For old browsers*/
section, article, aside, footer, header, nav {
  display: block;
}


/*Custom fadeInUp*/
@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpSmall {
  animation-name: fadeInUpSmall;
}