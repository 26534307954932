@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
/*SVG animation in "WorkTogether"*/
.animateSvg {
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  -webkit-animation: dash 2s linear forwards;
          animation: dash 2s linear forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  color: white;
  background-color: black;
}

::-moz-selection {
  background: #D9FF6E;
  color: black;
}

::selection {
  background: #D9FF6E;
  color: black;
}


/*For old browsers*/
section, article, aside, footer, header, nav {
  display: block;
}


/*Custom fadeInUp*/
@-webkit-keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.fadeInUpSmall {
  -webkit-animation-name: fadeInUpSmall;
          animation-name: fadeInUpSmall;
}
